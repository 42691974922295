<template>
  <div class="course-detail">
    <a-spin :spinning="spinning">
      <div class="spin-content">
        <div class="title">年级</div>
        <ul class="grades">
          <li
            class="grade-item"
            :class="[
              currentGrade.grade == item.grade ? 'grade-item-select' : '',
            ]"
            v-for="item in gradeList"
            :key="item.grade"
            @click="touchselectGrade(item)"
          >
            {{ item.grade }}
          </li>
        </ul>
        <!-- 上下册 -->
        <a-divider />
        <ul class="volume">
          <li
            class="volume-item"
            :class="[
              currentSemester.semester == item.semester
                ? 'volume-item-select'
                : '',
            ]"
            v-for="item in semesters"
            :key="item.semester"
            @click="touchselectSemester(item)"
          >
            {{ item.semester }}
          </li>
        </ul>
        <a-divider />
        <!-- 单元 -->
        <div class="title">单元</div>
        <ul class="grades-unit">
          <li
            class="grade-unit-item"
            v-for="item in units"
            :class="[
              currentUnit.unidType == item.unidType ? 'grade-unit-select' : '',
            ]"
            :key="item.unidType"
          >
            <span class="unit-name" @click.stop="selectUnit(item)">{{
              item.unidType
            }}</span>
            <a-popconfirm
              ok-text="确定"
              cancel-text="取消"
              @confirm="delUnit(item)"
            >
              <template slot="title">
                <p style="font-weight: bold">删除单元提示</p>
                <p style="margin: 0">你确定要删除本单元及其所有课程吗？</p>
                <p style="margin: 0">删除后可以去博览馆重新添加最新课程。</p>
              </template>
              <a-button type="link" icon="delete"></a-button>
            </a-popconfirm>
          </li>
          <li class="grade-unit-item" @click="addUnit">
            <button class="unit-name add-part">新建单元</button>
          </li>
        </ul>
        <a-divider />
        <!-- 章节 -->
        <ul class="unit-child">
          <li class="grade-unit-item">
            <a-button type="default" size="large" @click="newLession"
              >新建课程</a-button
            >
          </li>
          <li class="unit-item" v-for="item in parties" :key="item.LessonId">
            <div class="part">
              <img
                class="arrow"
                src="@/assets/breadcrumb.png"
                alt=""
                srcset=""
              />
              <p class="part-title">{{ item.LessonName }}</p>
              <a-tag v-if="item.AuditState == 1" color="#108ee9">待审核</a-tag>
              <a-tag v-if="item.AuditState == 2" color="#87d068">已通过</a-tag>
              <a-tag v-if="item.AuditState == 3" color="#f50">已驳回</a-tag>
              <a-tag v-if="item.Extend1 != ''" color="#108ee9">精品</a-tag>
              <a-popconfirm
                ok-text="确定"
                cancel-text="取消"
                @confirm="delLesson(item)"
              >
                <template slot="title">
                  <p style="font-weight: bold">删除所有课时提示</p>
                  <p style="margin: 0">你确定要删除本课及其所有课时内容吗？</p>
                  <p style="margin: 0">删除后可以去博览馆重新添加最新课程。</p>
                </template>
                <a-button type="link" icon="delete"></a-button>
              </a-popconfirm>
              <a-popconfirm
                v-if="item.AuditState == 0"
                ok-text="确定"
                cancel-text="取消"
                @confirm="uploadUnit(item.LessonId)"
              >
                <template slot="title">
                  <p style="font-weight: bold">提交审核</p>
                  <p style="margin: 0">确认将该课程(所有课时)提交审核？</p>
                </template>
                <a-button type="text" class="bt-text">提交审核</a-button>
              </a-popconfirm>
            </div>
            <ul class="part-child">
              <li
                class="part-child-item"
                v-for="obj in item.myLessonSection"
                :key="obj.SectionId"
                @click="selectPart({ item: obj, name: item.LessonName })"
              >
                <img src="@/assets/link.png" alt="" srcset="" />
                <label>{{ obj.SectionName }}</label>
              </li>
              <li class="part-child-item" @click="addPart(item.LessonId)">
                <label>添加课时</label>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </a-spin>
    <a-modal
      :visible="partVisible"
      :title="titles[currentAdd]"
      :confirm-loading="partConfirmLoading"
      @ok="sureAddPart"
      @cancel="partVisible = false"
      cancelText="取消"
      okText="确定"
    >
      <div>
        <a-input
          v-model="newPartvalue"
          :placeholder="'请输入' + titles[currentAdd] + '名称'"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  addUnit,
  addLesson,
  addSection,
  getLessonLstByCourse,
  getUnitInfoById,
  deleteMyLesson,
  deleteMyUnit,
  applyAudit,
} from "@/api/unit";
export default {
  components: {},
  data() {
    return {
      gradeList: [],
      semesters: [],
      currentSemester: {},
      units: [],
      currentGrade: {},
      currentUnit: {},
      parties: [],
      spinning: true,
      titles: ["新建单元", "新建课程", "添加课时"],
      currentAdd: 0, //当前添加的类型
      partVisible: false,
      partConfirmLoading: false,
      newPartvalue: "", //将要添加的课时名称
      currentLessonId: "", //当前选择要添加课时的 课程ID
    };
  },
  mounted() {
    this.$store.commit("M_ADD_BOOK_ROUTER", [
      {
        path: this.$route.fullPath,
        title: this.$route.query.course,
      },
    ]);
    this.requestInfo();
  },
  methods: {
    requestInfo() {
      getLessonLstByCourse({ course: this.$route.query.course }).then((res) => {
        console.log("courseInfo", res);
        this.gradeList = res.data.gradeList;
        if (this.gradeList.length > 0) {
          //未选择年级
          if (Object.keys(this.currentGrade).length == 0) {
            this.selectGrade(this.gradeList[0]);
          } else {
            //有选择年级
            let obj = this.gradeList.find(
              (item) => item.grade === this.currentGrade.grade
            );
            this.selectGrade(obj || this.currentGrade);
          }
        } else {
          this.spinning = false;
        }
      });
    },
    /**
     * 选择年级
     */
    selectGrade(item) {
      this.currentGrade = item;
      this.semesters = item && item.semesterList ? item.semesterList : [];
      //未选择上下册
      if (Object.keys(this.currentSemester).length == 0) {
        this.selectSemester(this.semesters[0]);
      } else {
        //有选择上下册
        let obj = this.semesters.find(
          (item) => item.semester === this.currentSemester.semester
        );
        this.selectSemester(obj || this.currentSemester);
      }
    },
    /*
     * 选择上下册
     */
    selectSemester(item) {
      this.currentSemester = item;
      this.units = this.currentSemester.unitList;
      this.selectUnit(
        Object.keys(this.currentUnit).length == 0
          ? item.unitList[0]
          : this.currentUnit
      );
    },
    /*
     * 选择单元
     */
    selectUnit(item) {
      this.spinning = true;
      this.currentUnit = item;
      getUnitInfoById({ unitId: item.unidId }).then((res) => {
        console.log("unit part", res);
        this.parties =
          res.data && res.data.lessonList ? res.data.lessonList : [];
        this.spinning = false;
      });
    },
    /**
     * 删除整个单元
     */
    delUnit(item) {
      deleteMyUnit({ unitId: item.unidId }).then((res) => {
        this.spinning = false;
        this.$message.success(res.msg);
        if (res.code == 200) {
          const index = this.units.findIndex((obj) => {
            return obj.unidId == item.unidId;
          });
          this.units.splice(index, 1);
          this.parties = [];
        }
      });
    },
    /**
     * 删除所有课程
     */
    delLesson(item) {
      this.spinning = true;
      deleteMyLesson({ lessonId: item.LessonId }).then((res) => {
        this.spinning = false;
        this.$message.success(res.msg);
        if (res.code == 200) {
          const index = this.parties.findIndex((obj) => {
            return obj.LessonId == item.LessonId;
          });
          this.parties.splice(index, 1);
        }
      });
    },
    /**
     * 选择了课时
     */
    selectPart(item) {
      console.log(item);
      let query = {
        title: `${this.currentGrade.grade}/${this.currentSemester.semester}/${this.currentUnit.unidType}/${item.name}/${item.item.SectionName}`,
        unitId: this.currentUnit.unidId,
        course: this.$route.query.course,
        sectionId: item.item.SectionId,
      };
      this.$router.push({ name: "newlessonDesign", query });
    },
    //新建单元
    addUnit() {
      this.currentAdd = 0;
      this.partVisible = true;
    },
    //新建课程
    newLession() {
      this.currentAdd = 1;
      this.partVisible = true;
    },
    //添加课时
    addPart(Id) {
      this.currentLessonId = Id;
      this.currentAdd = 2;
      this.partVisible = true;
    },
    //确认添加课时
    sureAddPart() {
      console.log(this.currentUnit);
      console.log(this.currentGrade);
      console.log(this.currentSemester);
      this.spinning = true;
      this.partVisible = false;

      if (this.currentAdd == 0) {
        //新建单元
        addUnit({
          HallName: this.$route.query.course,
          UnitName: this.newPartvalue,
          Grade: this.currentGrade.grade,
          Semester: this.currentSemester.semester == "下册" ? 2 : 1,
        }).then((res) => {
          this.spinning = false;
          this.$message.success(res.msg);
          this.requestInfo();
          // this.selectUnit(item.unitList[0]);
        });
      } else if (this.currentAdd == 1) {
        //添加课程
        addLesson({
          UnitId: this.currentUnit.unidId,
          LessonName: this.newPartvalue,
          TeachTime: 1,
          Grade: this.currentGrade.grade,
          Semester: this.currentSemester.select == 1 ? 2 : 1,
        }).then((res) => {
          this.spinning = false;
          this.$message.success(res.msg);
          this.selectUnit(this.currentUnit);
        });
      } else {
        //添加课时
        addSection({
          LessonId: this.currentLessonId,
          SectionTitle: this.newPartvalue,
        }).then((res) => {
          this.spinning = false;
          this.$message.success(res.msg);
          this.selectUnit(this.currentUnit);
        });
      }
    },
    //提交审核
    uploadUnit(id) {
      this.spinning = true;
      applyAudit({ lessonid: id }).then((res) => {
        this.spinning = false;
        this.$message.success(res.msg);
        this.selectUnit(this.currentUnit);
      });
    },
    //点击切换年级
    touchselectGrade(item) {
      this.currentUnit = {};
      this.currentSemester = {};
      this.selectGrade(item);
    },
    //点击切换上下册
    touchselectSemester(item) {
      this.currentUnit = {};
      this.selectSemester(item);
    },
  },
};
</script>

<style lang="less">
.course-detail {
  .title {
    margin-top: 46px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium;
    line-height: 22px;
    color: #2a2a31;
    font-weight: bold;
  }
  .grades {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    .grade-item {
      margin-right: 36px;
      margin-top: 36px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2a2a31;
      text-align: center;
      padding: 6px 20px;
      background: white;
      cursor: pointer;
      border-radius: 4px;
    }
    .grade-item-select {
      background: #6e76ff;
      color: white;
    }
  }
  .volume {
    padding: 24px 0;
    display: flex;
    .volume-item {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2a2a31;
      margin-right: 36px;
      cursor: pointer;
    }
    .volume-item-select {
      color: #6e76ff;
    }
  }
  .grades-unit {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .grade-unit-item {
      margin-right: 36px;
      margin-top: 36px;
      overflow: visible;
      border-radius: 4px;
      .unit-name {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a2a31;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        display: inline-block;
        padding: 6px 20px;

        &.add-part {
          background-color: #fff;
          border: none;
        }
      }
    }
    .grade-unit-select {
      color: white;
      .unit-name {
        background: #6e76ff;
        color: white;
      }
    }
  }
  .unit-child {
    padding: 0;

    .grade-unit-item {
      margin-right: 36px;
      margin-top: 36px;
      overflow: visible;
      border-radius: 4px;
      .unit-name {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a2a31;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        display: inline-block;
        padding: 6px 20px;

        &.add-part {
          background-color: #fff;
          border: none;
        }
      }
    }

    .unit-item {
      padding-top: 30px;
      padding-left: 0px;
      .part {
        display: flex;
        align-items: center;
        .arrow {
          width: 8px;
          height: 8px;
          object-fit: contain;
        }
        .part-title {
          margin: 0;
          padding-left: 8px;
          font-size: 16px;
          color: #2a2a31;
          font-weight: bold;
        }
        .bt-text {
          color: #6e76ff;
          border: none;
          background-color: rgba(0, 0, 0, 0);
        }
      }
      .part-child {
        padding: 0;
        margin-left: 16px;
        display: flex;
        .part-child-item {
          font-size: 14px;
          padding: 4px 34px 4px 13px;
          background: white;
          margin-right: 14px;
          margin-top: 15px;
          cursor: pointer;
          label {
            padding-left: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>